<template>
  <section class="publicfund">
    <div class="publicfund_cont">
      <div class="title_">
        <router-link :to="{name:'RechargeS1'}" class="iconfont arrleft"
          >&#xe665;</router-link
        >
        惠金宝
      </div>
      <div class="side_cont">
        <ProgressBar :sideData="side" />
      </div>
      <div class="solids_"></div>
      <div class="buttom">
        <div class="card">
          <div class="bankcard">{{ bancknum }}</div>
          <div class="card-text">
            <div>您即将充值至 <span>惠金宝</span></div>
            <div>
              <span>{{ numprice || "--" }}</span>
              <span>元</span>
            </div>
            <div>{{ payType == '0' ? '快捷支付' : '线下转账'}}</div>
          </div>
        </div>
        <div class="bankcard_" v-if="false">
          <div class="bank_box">
            <div :class="cardindex==i?'backs_ac':''" @click="getcard(item,i)" v-for="(item,i) in play" :key="i"
              class="banks_">
              <div class="top_ban">
                <img :src="item.logoUrl" alt="">
                <span class="bank_name">{{item.bankName}}</span>
              </div>
              <div class="cardnum_">{{item.bankAccounts}}</div>
              <div v-if="cardindex==i" class="right_true"><span>✓</span></div>
            </div>
          </div>
        </div>
        <div class="confirm_" v-if="payType=='1'">
          <div class="title_confirm">
            <span class="letf_ti">核对充值金额</span><span class="rule_s">*请准确核对转账信息，否则将导致匹配失败</span>

          </div>
          <div class="table_confirm_">
            <div class="td_">
              <div class="l_ti">充值项目</div>
              <div class="r_cont">
                <span class="cont_">惠金宝</span>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">转账金额</div>
              <div class="r_cont">
                <input ref="o_aUrl1" class="cont_" type="text" :value="numprice" readonly />
                <a @click="copynum" class="copy_">复制</a>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">收款户名</div>
              <div class="r_cont">
                <input ref="o_aUrl2" class="cont_" type="text" value="众惠基金销售有限公司" readonly />
                <a @click="copysj" class="copy_">复制</a>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">开户银行</div>
              <div class="r_cont">
                <span class="cont_">中国民生银行股份有限公司贵阳分行营业部</span>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">收款账号</div>
              <div class="r_cont">
                <input ref="o_aUrl3" class="cont_" type="text" value="698396801" readonly />
                <a @click="copyzh" class="copy_">复制</a>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">大额收款账号</div>
              <div class="r_cont">
                <input ref="o_aUrl3" class="cont_" type="text" value="305701000027" readonly />
                <a @click="copyzh" class="copy_">复制</a>
              </div>
            </div>
          </div>
        </div>
        <div class="input-top">
          <div>请输入交易密码：</div>
          <a @click="forget">忘记密码？</a>
        </div>
        <input
          type="password"
          v-model="mypassworld"
          placeholder="请输入6位交易密码"
          maxlength="6"
        />
        <!-- <div class="errmsg">{{errmsg}}</div> -->
        <div class="bt">
          <a class="btn1" @click="sure">确定</a>
          <router-link :to="{ name: 'RechargeS1' }" class="btn2"
            >返回上一步</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ProgressBar from "@/components/ProgressBar";
import { fullNew } from "@/api/recharge";
import { findTradeBankAcct} from "@/api/addfund";

export default {
  name: "RechargeS2",
  components: { ProgressBar },
  data() {
    return {
      bancknum: "",
      errmsg: " ",
      mypassworld: null,
      side: [
        { title: "填写充值信息", type: 1 },
        { title: "输入交易密码", type: 2 },
        { title: "交易结果", type: 0 },
      ],
      numprice: null,
      payType:'',
      play: [],
      cardindex:'0',
      bankcard:{}
    };
  },
  methods: {
    forget() {
      this.$router.push("/myFavors/accountManage/safetyCentre");
    },
    sure() {
      if (/^\d{6}$/.test(this.mypassworld)) {
        fullNew({
          balance: sessionStorage.getItem("balance"),
          tradePassword: this.mypassworld,
          payType: parseInt(this.payType)+2,
          bankAccount:this.bankcard.bankAccount,
          capitalMode:this.bankcard.capitalMode,
          channelSource:'1'
        })
          .then((res) => {
            if (res.code == 200) {
              this.$router.push({ name: "RechargeS3" });
            }
          })
          .catch((err) => {
            this.errmsg = err;
          });
      } else {
        this.$message.error("请输入6位数字密码");
      }
    },
    getcard(obj, i) {
      this.cardindex = i;
      this.bankcard = obj;
    },
    copysj() {
      let input = this.$refs.o_aUrl2;
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    copyzh() {
      let input = this.$refs.o_aUrl3;
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    copynum() {
      let input = this.$refs.o_aUrl1;
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
  },
  created() {
    // findTradeBankAcct().then(result=>{
    //   let data = result.data
    //   data.forEach(m=>{
    //     m.bankAccounts = m.bankAccount.slice(0, 4) + ' **** **** '+m.bankAccount.slice(-4)
    //   })
    //   if(data.length){
    //     this.bankcard = data[0]
    //   }
    //   this.play = data
    // })
  },
  mounted() {
    this.numprice = sessionStorage.getItem("balance");
    let banckdata = JSON.parse(localStorage.getItem("banckdata"));
    this.bankcard=banckdata;
    // console.log(banckdata.banckNo)
    this.bancknum = banckdata.bankAccount
      .replace(/\s/g, "")
      .replace(/(.{4})/g, "$1 ");
    this.payType = this.$route.query.type;
    console.log('--23',this.payType)
  },
};
</script>
<style lang="less" scoped>
::-webkit-input-placeholder {
  color: #a5a5a5;
  font-size: 14px;
}
.profitac {
  color: #e45247 !important;
}
.profit {
  color: #01b834 !important;
}
.publicfund {
  padding-bottom: 40px;
  min-height: 600px;
  display: flex;
  .publicfund_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      padding: 21px 40px 0px 43px;
      height: 60px;
      font-weight: 500;
      font-size: 20px;
      color: #1f1f1f;
      .arrleft {
        margin-right: 14px;
        color: #bdc0cb;
      }
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }

    .buttom {
      width: 520px;
      height: 367px;
      margin-top: 30px;
      margin-left: 40px;
      .card {
        display: flex;
        .bankcard {
          background-image: url("../../../static/img/my/bank card.png");
          width: 268px;
          height: 161px;
          border-radius: 14px;
          font-size: 18px;
          color: #ffffff;
          text-align: center;
          line-height: 161px;
        }
        .card-text {
          margin: 34px 0 0 58px;
          div {
            &:first-child {
              font-size: 16px;
              font-weight: 300;
              color: #1f1f1f;
              margin-bottom: 10px;
              span {
                color: #ce9b63;
              }
            }
            &:nth-child(2) {
              font-size: 26px;
              color: #e45247;
              margin-bottom: 10px;
              span {
                &:nth-child(2) {
                  font-size: 14px;
                  margin-left: 6px;
                }
              }
            }
            &:last-child {
              font-size: 16px;
              font-weight: 300;
              color: #1f1f1f;
            }
          }
        }
      }
      .input-top {
        display: flex;
        margin-top: 40px;
        div {
          &:first-child {
            font-size: 16px;
            font-weight: 400;
            color: #1f1f1f;
          }
        }
        a {
          font-size: 14px;
          font-weight: 400;
          color: #a5a5a5;
          margin-left: 322px;
        }
      }
      input {
        width: 520px;
        height: 48px;
        margin-top: 14px;
        border: 1px solid #e0e2e8;
        border-radius: 2px;
        padding-left: 24px;
      }
      .errmsg {
        height: 20px;
        color: red;
      }
      .bt {
        margin-top: 40px;
        display: flex;
        .btn1 {
          width: 240px;
          height: 48px;
          background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
          border-radius: 2px;
          color: #ce9b63;
          font-size: 16px;
          text-align: center;
          line-height: 48px;
        }
        .btn2 {
          color: #ce9b63;
          font-size: 16px;
          margin-left: 20px;
          line-height: 48px;
        }
      }
    }
  }
}

  .bankcard_ {
    margin-bottom: 30px;
    padding: 30px 24px 6px 24px;
    margin-top: 24px;
    background: #f6f5f3;

    .bank_box {
      display: flex;
      // align-items: center;
      // justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      border-radius: 4px;

      .backs_ac {
        width: 208px !important;
        border: 1px solid #ce9b63;
        height: 54px !important;
        border-radius: 4px;
      }

      .banks_ {
        padding: 22px 20px;
        width: 210px;
        height: 56px;
        background: #fff;
        margin-right: 24px;
        margin-bottom: 24px;
        position: relative;
        border-radius: 4px;

        &:nth-child(4n + 4) {
          margin-right: 0px;
        }

        .right_true {
          position: absolute;
          right: -16.5px;
          top: -16.5px;
          width: 0px;
          height: 0px;
          border: 16px solid #f7d9b7;
          border-color: transparent transparent #eabf96 transparent;
          transform: rotate(45deg);

          // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
          span {
            color: #fff;
            position: absolute;
            transform: rotate(-35deg);
            top: -1px;
            right: -5px;
          }
        }

        .top_ban {
          display: flex;
          align-items: center;

          img {
            width: 22px;
            height: 22px;
            margin-right: 7px;
          }

          .bank_name {
            font-size: 18px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #1f1f1f;
          }
        }

        .cardnum_ {
          margin-top: 20px;
          font-size: 18px;
          font-family: Bahnschrift, Bahnschrift-Light;
          font-weight: 300;
          color: #1f1f1f;
        }
      }

      .addbank_ {
        width: 249px;
        height: 98px;
        border: 1px dashed #bdc0cb;
        border-radius: 4px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .add_ {
          font-size: 18px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #bdc0cb;
        }
      }
    }

    .bank_type_btn {
      width: 98px;
      height: 28px;
      display: inline-block;
      background: #deb87b;
      border-radius: 4px;
      text-align: center;
      color: #fff;
      line-height: 28px;
      margin-left: 86%;
      margin-bottom: 24px;
    }
  }
  .confirm_ {
    margin-top: 30px;
  
    .title_confirm {
      .letf_ti {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        color: #1f1f1f;
      }
  
      .rule_s {
        margin-left: 178px;
        font-size: 12px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
        font-weight: 300;
        color: #e45247;
      }
    }
  
    .table_confirm_ {
      margin-top: 24px;
  
      .td_ {
        display: flex;
        align-items: center;
  
        .l_ti {
          width: 120px;
          height: 47px;
          border-bottom: 1px solid #fff;
          background: #f3f4f6;
          line-height: 47px;
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #1f1f1f;
          text-indent: 2em;
        }
  
        .r_cont {
          width: 379px;
          height: 47px;
          padding-right: 20px;
          border-top: 1px solid #f3f4f6;
          border-right: 1px solid #f3f4f6;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:last-child {
            border-bottom: 1px solid #f3f4f6;
          }
          .cont_ {
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            color: #1f1f1f;
            text-indent: 2em;
            width: 70%;
            border:none;
            padding-left:0;
          }
  
          .copy_ {
            display: inline-block;
            background: #f6f5f3;
            width: 44px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            text-align: center;
            color: #8691a8;
          }
        }
      }
    }
  }
</style>
