import request from '@/utils/request'

// 我的资产-充值
export function recharge(data) {

  return request({
    url: '/api/sale/trade/xjb/full',
    method: 'post',
    data
  })
}
// 多卡充值惠金宝新接口
export function fullNew(data) {
  return request({
    url: '/api/sale/trade/xjb/fullNew',
    method: 'POST',
    data
  })
}
// 我的资产-自动分配金额
export function autoDistribute(data) {
  return request({
    url: '/api/sale/trade/xjb/autoDistribute',
    method: 'post',
    data
  })
}